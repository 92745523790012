import React, { Suspense, useState } from 'react';
import { useEffect, useRef } from 'react'
import './App.scss';
import { BrowserRouter, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux'
import { Toast } from 'primereact/toast';
import DocumentTitle from 'react-document-title'


//store
import { IStoreState } from './types/StoreState'

//service and action
import { checkIsAdmin, getAuthCodeSuccess, getUserInfo, getUserInfoSuccess, getUserRole } from './actions/loginAction'
import TokenService from './api/token.service';

//Guards
import LoginAuthGuard from './Guard/LoginAuthGuard';

//components
import ManageNotification from './components/BIPortal/ManageNotification/ManageNotification';
import UserNotification from './components/UserNotification/UserNotification';
import Footer from './components/Footer/Footer'
import LoginRedirect from './components/loginRedirect/loginRedirect'


import Loader from './components/shared/loader/Loader'

import ErrorHandler from './components/ErrorHandler/ErrorHandler'
import { config } from './api/config';
import { getFavouriteReport } from './actions/reportListAction'
import { showNotification } from './actions/notificationAction';

import BIPortal from './components/BIPortal/BIPortal';
import { updateStatus } from './actions/DataRefreshingAction';
import RoleAuthGuard from './Guard/RoleAuthGuard';
import SmartRouteNavigator from './Navigator/SmartRouteNavigator';
const Timesheet = React.lazy(() => import('src/components/Timesheet/Timesheet'));

const Smart = React.lazy(() => import('./components/Smart/Smart'))
const ClientAnalytics = React.lazy(() => import('./components/ClientAnalytics/ClientAnalytics'))
const CustomDashboard = React.lazy(() => import('./components/CustomDashboard/CustomDashboard'));
const CreateYourOwn = React.lazy(() => import('./components/CreateYourOwn/CreateYourOwn'));
const Leaderboard = React.lazy(() => import('./components/Leaderboard/Leaderboard'));
//class start
const App = (props: any) => {
  const toast = useRef(null);
  useEffect(() => {
    const user = TokenService.getUser();
    const userInfo = TokenService.getUserInfo()
    if (user) {
      props.getAuthCodeSuccess(user)
    }
    if (userInfo) {
      props.getUserInfoSuccess(userInfo)
      if (props.userRoles && !props.userRoles.loading && !props.userRoles.success) {
        props.getUserRole()
      }
    }
    window.addEventListener("beforeunload", async (ev) => {
      const token = TokenService.getTableauToken()
      if (token) {
        ev.preventDefault()
        const userInfo = TokenService.getUserInfo()
        const res = navigator.sendBeacon(config.baseUrl + config.apiUrl.deleteTokenOnClose + `?userId=${userInfo && userInfo.sub}`)
      }
    });

  }, [])


  useEffect(() => {
    if (props.isLogin && props.isLogin.data && props.isLogin.accessToken) {
      props.getFavouriteRepots();
      /*TODO: If we want to show data refresh notification uncomment this.
      props.checkIsUpdating();*/
      if (props.userInfo && !props.userInfo.loading && !props.userInfo.success) {
        props.getUserInfo()
      }
      if (props.userRoles && !props.userRoles.loading && !props.userRoles.success) {
        props.getUserRole()
      }
    }
  }, [props.isLogin])


  useEffect(() => {
    if (props.notification?.severity) {
      showNotification(toast)
    }
  }, [props.notification])

  const showNotification = (toast: any) => {
    if (toast && toast.current && toast.current.show) {
      toast.current.show({ ...props.notification });
    }
  }


  return (
    <div className="App">
      <Toast ref={toast} />

      {/* <BrowserRouter basename='/user_reports/analyticsportal'> */}
      <BrowserRouter>
        <Routes>

          <Route path='/timesheet/*' element={
            <Suspense fallback={<Loader show={1} text={'loading...'} />}>
              <DocumentTitle title='Timesheet'>
                <LoginAuthGuard isLogin={props.isLogin.data}>
                  <Timesheet />
                </LoginAuthGuard>
              </DocumentTitle>
            </Suspense>} />
          <Route path='/smart/*' element={
            <Suspense fallback={<Loader show={1} text={'loading...'} />}>
              <DocumentTitle title='SMART'>
                <LoginAuthGuard isLogin={props.isLogin.data}>
                  <Smart />
                </LoginAuthGuard>
              </DocumentTitle>
            </Suspense>} />

          <Route path='/clientAnalytics/*' element={
            <Suspense fallback={<Loader show={1} text={'loading...'} />}>
              <DocumentTitle title='Client-Analytics'>
                <LoginAuthGuard isLogin={props.isLogin.data}>
                  <RoleAuthGuard requireRole={[12]}>
                    <ClientAnalytics />
                  </RoleAuthGuard>
                </LoginAuthGuard>
              </DocumentTitle>
            </Suspense>} />

          <Route path='/customdashboard/*' element={
            <Suspense fallback={<Loader show={1} text={'loading...'} />}>
              <DocumentTitle title='Custom Dashboards'>
                <LoginAuthGuard isLogin={props.isLogin.data}>
                  <RoleAuthGuard requireRole={[13]}>
                    <CustomDashboard />
                  </RoleAuthGuard>
                </LoginAuthGuard>
              </DocumentTitle>
            </Suspense>} />

          <Route path='/createDashboard/*' element={
            <Suspense fallback={<Loader show={1} text={'loading...'} />}>
              <DocumentTitle title='DIY'>
                <LoginAuthGuard isLogin={props.isLogin.data}>
                  <RoleAuthGuard requireRole={[14]}>
                    <CreateYourOwn />
                  </RoleAuthGuard>
                </LoginAuthGuard>
              </DocumentTitle>
            </Suspense>} />

          <Route path='/leaderboard/*' element={
            <Suspense fallback={<Loader show={1} text={'loading...'} />}>
              <DocumentTitle title='Leaderboard'>
                <LoginAuthGuard isLogin={props.isLogin.data}>
                  <Leaderboard />
                </LoginAuthGuard>
              </DocumentTitle>
            </Suspense>} />

          <Route
            path="/report/:kpi/:reportName"
            element={
              <SmartRouteNavigator />
            }
          />

          <Route
            path="/kpi/:kpiId"
            element={
              <SmartRouteNavigator />
            }
          />


          <Route path='/loginSSO' element={
            <LoginRedirect></LoginRedirect>} />


          <Route path="/404" element={<ErrorHandler title='404 - Page Not Found' description='Sorry we were unable to find that page' emoji='mdi-emoticon-sad-outline' />} />


          <Route path='/*' element={
            <DocumentTitle title='SHL-Analytics'>
              <LoginAuthGuard isLogin={props.isLogin.data}>
                <BIPortal />
              </LoginAuthGuard>
            </DocumentTitle>
          } />
          <Route path="*" element={<Navigate to="/404" />} />
        </Routes>
      </BrowserRouter>

    </div>
  );
}


//redux work start
const mapStateToProps = (state: IStoreState) => ({
  isLogin: state.login.isLogin,
  isAdmin: state.login.isAdmin,
  userInfo: state.login.userInfo,
  userRoles: state.login.userRoles,
  notification: state.notification.notification,
  isUpdating: state.dataRefresh.isUpdating,
  loader: state.notification.loader,
  token: state.tableauReport.token
})

const mapDispatchToProps = (dispatch: any) => ({
  checkIsAdmin: () => {
    dispatch(checkIsAdmin())
  },
  getAuthCodeSuccess: (payload: any) => {
    dispatch(getAuthCodeSuccess(payload))
  },
  getUserInfo: () => {
    dispatch(getUserInfo())
  },
  getUserInfoSuccess: (payload: any) => {
    dispatch(getUserInfoSuccess(payload))
  },
  checkIsUpdating: () => {
    dispatch(updateStatus());
  },
  getUserRole: () => {
    dispatch(getUserRole())
  },
  getFavouriteRepots: () => {
    dispatch(getFavouriteReport());
  },
  setNotification: (data: any) => {
    dispatch(showNotification(data));
  }


})
export default connect(mapStateToProps, mapDispatchToProps)(App);
