import { Dropdown } from 'primereact/dropdown';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import '../FieldCommon.scss';
import './Select.scss';
import { debounceFunction } from 'src/util/debounce';
import { Tooltip } from 'primereact/tooltip';
import CustomTooltip from '../../CustomTooltip/CustomTooltip';

// Interface for options in the dropdown
interface IOptions {
    label: string, // The label of the option
    value: any, // The value of the option
    disabled?: boolean // Optional: Whether the option is disabled
}

// Interface for the Select component props
interface ISelect {
    update: (name: string, value: any, displayLabel: string, valueLabels?: any) => void,
    selectOptions?: IOptions[],
    label: string,
    name: string,
    disabled?: boolean,
    error?: boolean,
    value?: any,
    onFilter?: (event: any) => void,
    panelClass?: string,
    onBlur?: () => void,
    showClear?: boolean,
    isValueNumber?: boolean,
    dontConvert?: boolean,
    disabledItemText?: string
}

const Select = ({ update, selectOptions, label, name, disabled, error, value, isValueNumber, dontConvert, disabledItemText }: ISelect) => {
    const [innerValue, setValue] = useState<any>();
    const staleClosureInnerValue = useRef(innerValue);
    // State for the labels
    const [labels, setLabels] = useState<any>();
    // Ref for the labels
    const staleClosureLabels = useRef(labels);
    // Ref for the prop value
    const staleColsurePropValue = useRef(value);

    // State for the search string
    const [searchString, setSearchString] = useState<string>('');

    // Debounced function for searching
    const debounceSearch = useCallback(debounceFunction((nextValue: string, list: IOptions[], innerValue: any) => updateList(nextValue, list, innerValue), 400), []);

    // State for the list
    const [list, setList] = useState<any[]>([]);
    // State for waiting for select options
    const [waitForSelectOptions, setWaitForSelectOptions] = useState<boolean>(false);

    const ref = useRef<any>();
    const searchInput = useRef<any>();

    // Memoized width for the dropdown
    const width = useMemo(() => {
        if (ref && ref.current) {
            return ref.current.offsetWidth;
        } else {
            return '200px';
        }
    }, [ref.current]);

    // Handler for hiding the dropdown
    const handler = (e: any) => {
        if (e.currentTarget.id === `${name.replace(/[^a-zA-Z0-9]/g, '')}_dropdown`) {
            console.log(innerValue);
            hide();
        }
    }

    const onDropdownOpen = (e: any) => {
        if (e.currentTarget.id === `${name.replace(/[^a-zA-Z0-9]/g, '')}_dropdown`) {
            // window.sessionStorage.setItem('opendropdown', `${name}_dropdown`);
            searchInput.current.focus();
        }
    }

    useEffect(() => {
        staleClosureLabels.current = labels;
    }, [labels]);

    // Effect for updating the inner value
    useEffect(() => {
        staleClosureInnerValue.current = innerValue;
    }, [innerValue]);

    // Effect for updating the prop value
    useEffect(() => {
        staleColsurePropValue.current = value;
    }, [value]);

    // Effect for adding and removing the hide event listener
    useEffect(() => {
        let dropdown = ref.current;
        if (dropdown) {
            dropdown.addEventListener('hide.bs.dropdown', handler)
            dropdown.addEventListener('shown.bs.dropdown', onDropdownOpen);
            return () => {
                dropdown.removeEventListener('hide.bs.dropdown', handler);
                dropdown.removeEventListener('shown.bs.dropdown', onDropdownOpen);
            }
        }
    }, [])

    useEffect(() => {
        updateValue(value);
    }, [value])

    useEffect(() => {
        debounceSearch('', selectOptions, innerValue)
        if (waitForSelectOptions) {
            setWaitForSelectOptions(false);
            updateValue(value);
        }
    }, [selectOptions]);

    const updateValue = (value: any) => {
        if (dontConvert) {
            let l = getLabelsValues(value);
            if (l) {
                setValue(value)
                setLabels(l)
            }
        } else if (value) {
            if (isValueNumber) {
                let l = getLabelsValues(value);
                if (l) {
                    setValue(value)
                    setLabels(l)
                }
            } else {
                let l = getLabelsValues(value);
                if (l) {
                    setValue(value.toString())
                    setLabels(l)
                }
            }
        } else {
            setValue(undefined)
            setLabels('')
        }
    }

    const getLabelsValues = (value: any) => {
        let labels: string = '';
        if (!selectOptions || selectOptions.length === 0) {
            setWaitForSelectOptions(true);
            return labels;
        }

        let f = selectOptions.find((i: IOptions) => {
            return i.value.toString() === value.toString()
        })

        if (f) labels = f.label;

        return labels;
    }



    const updateList = (value: any, selectOptions: IOptions[], innerValue: any) => {
        if (selectOptions) {
            let fItems: IOptions[] = [];
            if (value) {
                for (let item of selectOptions) {
                    if (item && item.label.toLowerCase().includes(value.toLowerCase())) {
                        fItems.push(item);
                    }
                }
            } else {
                fItems = selectOptions.slice(0, 100);
            }
            if (fItems.length === 0) {
                fItems.push({
                    label: `No ${label} is available.`,
                    value: 'na',
                    disabled: true
                })
            }
            setList(fItems)
        } else {
            let fItems: IOptions[] = [{
                label: `No ${label} is available`,
                value: 'na',
                disabled: true
            }];
            setList(fItems)
        }
    }

    const hide = () => {
        if (staleColsurePropValue.current != staleClosureInnerValue.current) {
            update(name, staleClosureInnerValue.current, label, staleClosureLabels.current)
        }
    }

    const onChange = (event: any, value: any, label: any) => {
        const selected = event && event.currentTarget && event.currentTarget.classList.contains('selected')
        if (!selected) {
            let v = value;
            setValue(v);
            let l = label;
            setLabels(l);
            setTimeout(() => {
                ref.current.click();
            }, 0);
        } else {
            setValue(undefined);
            setLabels('')
        }
    }

    const removeValue = () => {
        setValue(undefined);
        setLabels('')
        setTimeout(() => {
            ref.current.click();
        }, 0);
    }


    const onSearch = (event: any) => {
        setSearchString(event.currentTarget.value);
        debounceSearch(event.currentTarget.value, selectOptions, innerValue)
    }

    return (<div className='singleselect_container'>
        <div ref={ref} className='value-container form-control cursor-pointer'
         style={{ pointerEvents: disabled ? 'none' : 'auto', borderColor: error ? 'rgb(220, 53, 69)' : '', opacity: disabled ? '0.6' : '1' }}
         id={`${name.replace(/[^a-zA-Z0-9]/g, '')}_dropdown`}
          data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
            {!innerValue || innerValue.length === 0 ? <span className='placeholder-text me-auto'>{`Select ${label}`}</span> : <>
                <CustomTooltip text={labels} target={`.${name.replace(/[^a-zA-Z0-9]/g, '')}-value`} />

                <span className={`${name.replace(/[^a-zA-Z0-9]/g, '')}-value value me-auto`}  >
                    {labels}
                </span>
                <span className='pi pi-times down-icon me-3' onClick={removeValue}></span>
            </>}
            <span className='pi pi-chevron-down down-icon'></span>
        </div>
        <div className='suggestion-box dropdown-menu p-0' style={{ width: width }} aria-labelledby={`${name.replace(/[^a-zA-Z0-9]/g, '')}_dropdown`}>
            <div className='d-flex align-items-center border-bottom p-2 px-3 search-container'>
                <input ref={searchInput} type='text' className='form-control' placeholder='Type to search' onChange={onSearch} value={searchString} />
            </div>
            <ul className=''>
                {list?.map(item =>
                    item.disabled ?
                        <>
                            <CustomTooltip text={disabledItemText ? disabledItemText : 'This option is disabled not available for selection'} target={`.value-${item.value.toString().replace(/[^a-zA-Z0-9]/g, '')}`} />

                            <span className={`value-${item.value.toString().replace(/[^a-zA-Z0-9]/g, '')}`} >
                                <li key={item.value} className={`${item.disabled ? 'disabled' : ''} ${item.value == innerValue ? 'selected' : ''}`} style={{ pointerEvents: item.disabled ? 'none' : 'auto' }} onClick={(e: any) => onChange(e, item.value, item.label)}>
                                    <label htmlFor={`${name.replace(/[^a-zA-Z0-9]/g, '')}_${item.value}`} className='w-100 py-3 px-3 d-flex align-items-center'>
                                        <span className=' fw-normal l2-font-size' >{item.label}</span>
                                        <span className='mdi mdi-close close-icon ms-auto'></span>
                                    </label>
                                </li></span></> : <li key={item.value} className={`${item.disabled ? 'disabled' : ''} ${item.value == innerValue ? 'selected' : ''}`} style={{ pointerEvents: item.disabled ? 'none' : 'auto' }} onClick={(e: any) => onChange(e, item.value, item.label)}>
                            <label htmlFor={`${name.replace(/[^a-zA-Z0-9]/g, '')}_${item.value}`} className='w-100 py-3 px-3 d-flex align-items-center'>
                                <span className=' fw-normal l2-font-size' >{item.label}</span>
                                <span className='mdi mdi-close close-icon ms-auto'></span>
                            </label>
                        </li>)}
            </ul>
        </div>
    </div>)
}

export default Select