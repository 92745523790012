import { ProgressSpinner } from 'primereact/progressspinner';
import { useLocation } from 'react-router';
import CaLoader from '../CaLoader/CaLoader';
import './Loader.scss'
interface ILoader {
  show: number,
  text: string
}

const Loader = ({ show, text }: ILoader) => {
  const location = useLocation();
  let application: string;
  if (location.pathname.includes('smart'))
    application = 'smart'
  else if (location.pathname.includes('clientAnalytics'))
    application = 'client-analytics'
  else application = 'A&IM'

  const getLoader = () => {
    switch (application) {
      case 'smart': {
        return (
          <div className='progress-container '>
            <div className="loader-div "></div>
            <span className="mdi mdi-alpha-s-box-outline"></span>
          </div >
        )
      }
      case 'client-analytics': {
        return <CaLoader></CaLoader>
      }
      default: return <CaLoader></CaLoader>
    }
  }

  return (show > 0 ? getLoader() : null)
}

export default Loader