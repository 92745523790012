import { IManageFilter } from "../types/ManageFilter";
import actionType from '../actions/ManageFilterActionTypes'

const initialState: IManageFilter = {
    filterList: {
        loading: false,
        success: false,
        error: false,
        filter: []
    },
    parentfilterList: {
        loading: false,
        success: false,
        error: false,
        filter: []
    },
    addFilterStatus: {
        loading: false,
        success: false,
        error: false
    },
    editFilterStatus: {
        loading: false,
        success: false,
        error: false
    },
    deleteFilterStatus: {
        loading: false,
        success: false,
        error: false
    },
    currentFilter: {
        reportId: -1,
        name: '',
        label: '',
        required: true,
        type: '',
        isCompanyFilter: false,
        sequence: 0
    },
    editingFilter: {
        reportId: -1,
        name: '',
        label: '',
        required: true,
        type: '',
        isCompanyFilter: false,
        sequence: 0
    }
}

const manageFilterReducer = (state = initialState, action: any): IManageFilter => {
    switch (action.type) {
        case actionType.GET_FILTER_LIST: {
            return {
                ...state,
                filterList: Object.assign({}, {
                    success: false,
                    error: false,
                    filter: []
                }, { loading: true })
            }
        }
        case actionType.GET_FILTER_LIST_SUCCESS: {
            return {
                ...state,
                filterList: Object.assign({}, state.filterList, { loading: false, filter: action.payload, success: true, error: false })
            }
        }
        case actionType.GET_FILTER_LIST_ERROR: {
            return {
                ...state,
                filterList: Object.assign({}, state.filterList, { loading: false, error: true, success: false, filter: [] })

            }
        }

        case actionType.GET_PARENT_FILTER_LIST: {
            return {
                ...state,
                parentfilterList: Object.assign({}, {
                    success: false,
                    error: false,
                    filter: []
                }, { loading: true })
            }
        }
        case actionType.GET_PARENT_FILTER_LIST_SUCCESS: {
            return {
                ...state,
                parentfilterList: Object.assign({}, state.parentfilterList, { loading: false, filter: action.payload, success: true, error: false })
            }
        }
        case actionType.GET_PARENT_FILTER_LIST_ERROR: {
            return {
                ...state,
                parentfilterList: Object.assign({}, state.parentfilterList, { loading: false, error: true, success: false, filter: [] })
            }
        }
        case actionType.ADD_FILTER: {
            return {
                ...state,
                addFilterStatus: Object.assign({}, {
                    success: false,
                    error: false,
                    loading: true
                })
            }
        }
        case actionType.ADD_FILTER_SUCCESS: {
            return {
                ...state,
                addFilterStatus: Object.assign({}, state.addFilterStatus, { loading: false, success: true, error: false })
            }
        }
        case actionType.ADD_FILTER_ERROR: {
            return {
                ...state,
                addFilterStatus: Object.assign({}, state.addFilterStatus, { loading: false, error: true, success: false })

            }
        }

        case actionType.EDIT_FILTER: {
            return {
                ...state,
                editFilterStatus: Object.assign({}, {
                    success: false,
                    error: false,
                    loading: true
                })
            }
        }
        case actionType.EDIT_FILTER_SUCCESS: {
            return {
                ...state,
                editFilterStatus: Object.assign({}, state.editFilterStatus, { loading: false, success: true, error: false })
            }
        }
        case actionType.EDIT_FILTER_ERROR: {
            return {
                ...state,
                editFilterStatus: Object.assign({}, state.editFilterStatus, { loading: false, error: true, success: false })

            }
        }

        case actionType.DELETE_FILTER: {
            return {
                ...state,
                deleteFilterStatus: Object.assign({}, {
                    success: false,
                    error: false,
                    loading: true
                })
            }
        }
        case actionType.DELETE_FILTER_SUCCESS: {
            return {
                ...state,
                deleteFilterStatus: Object.assign({}, state.deleteFilterStatus, { loading: false, success: true, error: false })
            }
        }
        case actionType.DELETE_FILTER_ERROR: {
            return {
                ...state,
                deleteFilterStatus: Object.assign({}, state.deleteFilterStatus, { loading: false, error: true, success: false })

            }
        }

        case actionType.SELECT_ADD_FILTER: {
            return {
                ...state,
                currentFilter: {
                    reportId: -1,
                    name: '',
                    label: '',
                    required: true,
                    type: '',
                    isCompanyFilter: false,
                    sequence: 0
                },
                editingFilter: {
                    reportId: -1,
                    name: '',
                    label: '',
                    required: true,
                    type: '',
                    isCompanyFilter: false,
                    sequence: 0
                },
            }
        }

        case actionType.SELECT_EDIT_FILTER: {
            return {
                ...state,
                currentFilter: Object.assign({}, action.payload),
                editingFilter: Object.assign({}, action.payload),
            }
        }

        case actionType.UPDATE_FILTER: {
            return {
                ...state,
                currentFilter: Object.assign({}, action.payload)
            }
        }

        case actionType.UPDATE_FILTER_LIST: {
            return {
                ...state,
                filterList: Object.assign({}, state.filterList, { loading: false, filter: action.payload, success: true, error: false })
            }
        }
        case actionType.CLEAR_FILTER: {
            return {
                ...state,
                currentFilter: {
                    reportId: -1,
                    name: '',
                    label: '',
                    required: true,
                    type: '',
                    isCompanyFilter: false,
                    sequence: 0
                },
                editingFilter: {
                    reportId: -1,
                    name: '',
                    label: '',
                    required: true,
                    type: '',
                    isCompanyFilter: false,
                    sequence: 0
                },
                addFilterStatus: {
                    loading: false,
                    success: false,
                    error: false
                },
                editFilterStatus: {
                    loading: false,
                    success: false,
                    error: false
                },
                deleteFilterStatus: {
                    loading: false,
                    success: false,
                    error: false
                },
            }
        }
        default:
            return state
    }
}

export default manageFilterReducer